import { AppDefaults, Toaster, ToasterType } from "../types";

export const defaultAppDefaults: AppDefaults = {
    app_version: 130,
    app_type: 10,
    device_token: "Website",
    device_type: 0,
    environment: process.env.REACT_APP_SERVER,
  };

export const default_toaster_values: Toaster = {
  status: false,
  type: ToasterType.Info,
  message: "",
  duration: 3000
}

export const routes_constants = {
  login: '/login',
  change_password: '/change-password',
  register: '/register',
  forgot: '/forgot',
  dashboard: '/dashboard'
}
  