import React, { lazy, Suspense } from 'react';

const LoginComponent = lazy(() => import('./login.component'));

const LazyLoginComponent = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <LoginComponent {...props} />
  </Suspense>
);

export default LazyLoginComponent;