import React from 'react';
import { createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider } from 'react-router-dom';
import LoginComponent from '../components/login/login.lazy';
import InvalidComponent from '../components/invalid/invalid.lazy';
import DashboardComponent from '../components/layout/dashboard/dashboard.lazy';
import PrivateRoutes from './PrivateRouting';
import ContractValidateComponent from '../components/contract-validate/contract-validate.component';
import ForgetPasswordLazyComponent from '../components/forget-password/forgot-password.lazy';
import SetPasswordLazyComponent from '../components/contract-validate/set-password/set-password.lazy';

const Routing: React.FC = () => {
  
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/" element={<Navigate to="login"/>} />
        <Route path="/login" element={<LoginComponent />} />
        <Route path="/register" element={<ContractValidateComponent />} />
        <Route path="/change-password" element={<SetPasswordLazyComponent />} />
        <Route path="/forgot" element={<ForgetPasswordLazyComponent />} />
        <Route path="/app" element={<PrivateRoutes />}>
          {/* <Route path="/Dashboard" element={<DashboardComponent />} />   */}
        </Route>
        <Route path="*" element={<InvalidComponent />} />
        {/* <Route element={<LayoutLazyComponent />}>
        </Route> */}
      </>
    )
  );

  return <RouterProvider router={router} />;
  
  // const routes = useRoutes([
  //   { path: '/', element: <LoginComponent /> },
  //   { path: '/login', element: <LoginComponent /> },
  //   { path: '/register', element: <ContractValidateComponent /> },
  //   { path: '/change-password', element: <SetPasswordLazyComponent /> },
  //   { path: '/forgot', element: <ForgetPasswordLazyComponent /> },
  //   { path: '*', element: <InvalidComponent /> },
  //   { path: '', element: <PrivateRoutes />,
  //     children: [
  //       // { path: '', redirect: 'dashboard' /> },
  //       { path: 'dashboard', element: <DashboardComponent /> },
  //       // Add other protected routes here
  //     ],
  //   },
  // ]);

  // return routes;
};

export default Routing;
