import { createSelector } from 'reselect';
import { RootState } from '../reducers';

export const selectRegisterDetails = createSelector(
  (state: RootState) => state.register,
  (register) => ({
    registerStep: register.registerStep,
    error: register.error,
    name: register.name,
    verification_token: register.verification_token,
    patient_id: register.patient_id,
    business_name: register.business_name,
    verification_status: register.verification_status,
    account_approved: register.account_approved,
    contract_code: register.contract_code,
    status: register.status,
    flag: register.flag,
    payment_date: register.payment_date,
    estimated_amount: register.estimated_amount,
    recurring_amount: register.recurring_amount
  })
);

