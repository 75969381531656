import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import { changeRegisterStep } from '../../../redux/actions/register.action';
import { RegisterSteps } from '../../../types/register.type';
import { RootState } from '../../../redux/reducers';
import { selectRegisterDetails } from '../../../redux/selectors';
import { Button } from 'react-bootstrap';
import './review-details.scss';

interface PaymentDetailsProps {}

const ReviewComponent: React.FC<PaymentDetailsProps> = () => {
  const { business_name, contract_code, payment_date, estimated_amount, recurring_amount, name } = useSelector((state: RootState) => selectRegisterDetails(state));
  
  const dispatch = useDispatch<AppDispatch>();

  const handleStepChange = () => {
    dispatch(changeRegisterStep(RegisterSteps.Step3));
  }

  return (
    <div className="container payment-details-container">
      <div className="row">
        <div className="col-12 text-left">
          <a href="/login" className="back-to-login">
            Back to Login
          </a>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-12 col-md-8">
          <div className="text-center">
            <p>Hello {name}!</p>
            <p>Please check your payment details:</p>
            <hr />
            <p>Contract Code: {contract_code}</p>
            <hr />
            <p>Estimated Service Amount: ${estimated_amount}</p>
            <hr />
            <p>Business Name: {business_name}</p>
            <hr />
            <p>
              Recurring Amount: {recurring_amount}
            </p>
            <hr />
            <p>Next Payment Date: {new Date(payment_date).toLocaleDateString()}</p>
          </div>
          <div className="text-center">
            <Button className="btn btn-primary next-button" onClick={handleStepChange}>Next</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewComponent;
