import React, { lazy, Suspense } from 'react';

const LazyLoginComponent = lazy(() => import('./invalid.component'));

const InvalidComponent = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <LazyLoginComponent {...props} />
  </Suspense>
);

export default InvalidComponent;
