import React, { lazy, Suspense } from 'react';

const GetVerificationComponent = lazy(() => import('./get-verification.component'));

const GetVerificationLazyComponent = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <GetVerificationComponent {...props} />
  </Suspense>
);

export default GetVerificationLazyComponent;
