import { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers';
import GetVerificationLazyComponent from './get-verification/get-verification.lazy';
import { RegisterSteps } from '../../types/register.type';
import ReviewComponent from './review-details/review-details.component';
import VerifyLazyComponent from './validate-contract/validate-contract.lazy';
import SetPasswordLazyComponent from './set-password/set-password.lazy';

interface ContractValidateComponentProps {}

const ContractValidateComponent: FC<ContractValidateComponentProps> = () => {
  const { registerStep } = useSelector((state: RootState) => state.register);
  
  const renderComponent = () => {
    switch (registerStep) {
      case RegisterSteps.Step1:
        return <GetVerificationLazyComponent />;
      case RegisterSteps.Step2:
        return <ReviewComponent />;
        case RegisterSteps.Step3:
          return <VerifyLazyComponent />;
        case RegisterSteps.Step4:
            return <SetPasswordLazyComponent />;
            default:
        return <GetVerificationLazyComponent />;
    }
  };

 return (
    <>
      {renderComponent()}
    </>  
  );
};

export default ContractValidateComponent;