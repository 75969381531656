import React, { lazy, Suspense } from 'react';

const VerifyComponent = lazy(() => import('./validate-contract.component'));

const VerifyLazyComponent = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <VerifyComponent {...props} />
  </Suspense>
);

export default VerifyLazyComponent;
