import React from 'react';
import { useSelector } from 'react-redux';
import { selectSharedDetails } from './redux/selectors';
import Routing from './routing/Routing';
import { RootState } from './redux/reducers';
import { NotificationToaster } from './components/common';

const App: React.FC = () => {
  const { toaster } = useSelector((state: RootState) => selectSharedDetails(state));  

  return (
    <>
      {toaster.status && (
        <NotificationToaster status={toaster.status} message={toaster.message} type={toaster.type} duration={toaster.duration} />
      )}
      </>
    );
};

export default App;
