import { combineReducers } from 'redux';
import authReducer from './auth.reducer'; // Ensure this path is correct
import registerReducer from './register.reducer';
import sharedReducer from './shared.reducer';

const rootReducer = combineReducers({
  auth: authReducer,
  register: registerReducer,
  shared: sharedReducer
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
