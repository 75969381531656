import React, { lazy, Suspense } from 'react';

const ForgotPasswordComponent = lazy(() => import('./forgot-password.component'));

const ForgetPasswordLazyComponent = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <ForgotPasswordComponent {...props} />
  </Suspense>
);

export default ForgetPasswordLazyComponent;
